import React from "react"
import Header from '../components/headnav';
import CategoryHeader from '../components/categoryHeader';
import Article from '../components/article';
import { DarkFooter } from '../components/footer';

export default ({ children, pageContext }) => (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column'}}>
        <Header />
        <div style = {{background: 'black'}}>
            <CategoryHeader currentCategory = { pageContext.frontmatter.category }/>
            <Article article = { pageContext.article }/>
        </div>
        <DarkFooter />
    </div>
)