import React from "react"
import styles from "./categoryHeader.module.scss"
import { Riches, Honor, Life } from '../components/category';

const categoryStyle = {
    riches: 'bg-green',
    honor: 'bg-red',
    life: 'bg-blue'
};

const detectCategory = () => /\/(\w+)\//.exec(window.location.href)[1];
const getClasses = (category, current) => {
    const base = 'ph2';

    if (current !== category ){
        return base + ' ' + styles[ category + '__inactive' ];
    }

    return base + ' ' + categoryStyle[ category ];
}


export default ( { currentCategory }) => {
    return (
        <div className="bg-black w-100 flex flex-column f3 pa5 tracked near-white items-center lh-copy">
            <span className="tl mh5-ns">Humility is the fear of the Lord;</span>
            <span className="tl mh5-ns">its wages are <Riches inactive={currentCategory !== 'riches'} /> and <Honor inactive={currentCategory !== 'honor'} /> and <Life inactive={currentCategory !== 'life'} />.</span>
            <span className="tl">- Proverbs 22:4</span>
        </div>
    )
}