import React from "react"
import { Link } from 'gatsby';
import styles from "./article.module.scss"
import Fade from './fade';

const renderArticle = ({ html }) => ({__html: html});

export default ( { article } ) => {
    return (
        <div className={styles.articleWrapper}>
            <Fade >
                <div
                    className={styles.articleText}
                    dangerouslySetInnerHTML={renderArticle(article)}
                />
                {/* <div className={styles.controlArrow + ' ' + styles.controlArrow_left} >
                    <Link to={article.prev}>&#10094;</Link>
                </div>
                <div className={styles.controlArrow + ' ' + styles.controlArrow_right} >
                    <Link to={article.next}>&#10095;</Link>
                </div> */}
            </Fade>
        </div>
    )
}